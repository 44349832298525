import React, { lazy } from 'react';
import { graphql } from 'gatsby'
import Helmet from 'react-helmet'
import Layout from 'components/layout'
const Privacidad = lazy(() => import('sections/hero/Privacidad.js'));
const PoliticaPrivacidad = lazy(() => import('sections/services/PoliticaPrivacidad.js'));
const Llamenos = lazy(() => import('sections/hero/Llamenos.js'));
const ContactCreative = lazy(() => import('sections/contact/ContactCreative.js'));
const Footer = lazy(() => import('sections/services/Footer.js'));

class Index extends React.Component {

  render() {
    
    
    return (
      <div>
        <Helmet>
          <title>Política de Privacidad: Protección de datos personales</title>
          <meta name="description" content="Nuestra Política de Privacidad garantiza la protección de tus datos personales. Conoce cómo manejamos la información que compartes con nosotros y tus derechos." />
        </Helmet>
        <Layout>
          <Privacidad />          
          <PoliticaPrivacidad />          
          <Llamenos />
          <ContactCreative />
          <Footer />
        </Layout>
      </div>
    )
  }
}

export default Index

export const creativeVideoTwoQuery = graphql`
  query {
    site {
      meta: siteMetadata {
        title
        description
      }
    }
  }
`